<template>
  <div>
    <AllLogs></AllLogs>
  </div>
</template>

<script>
const AllLogs = defineAsyncComponent(() =>
  import("@/components/logs/UserAccountLogs")
);
import { defineAsyncComponent } from "vue";
export default {
  components: {
    AllLogs,
  },
};
</script>
